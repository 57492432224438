.dashboard-wrapper {
    padding: 20px;
    max-width: 98%; /* Increase the max-width for a wider container */
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dashboard-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
}

.data-display {
    margin-top: 20px;
    width: 100%; /* Ensure the data display takes full width */
    align-items: center;
}

.table-wrapper{
    padding: 20px;
    max-width: 98%; /* Increase the max-width for a wider container */
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pagination-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.filters{
    width: 100%
}

.tabs{
    --lds-color-tab-button-active-bg: rgb(10, 51, 153);
    --lds-color-tab-header-border: rgb(49, 139, 13);
}

/* Add styles for the badge container */
.badge-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column; /* Arrange items in a column */
    margin-top: 5px; /* Space above the badges */
}

/* Style for the answer pattern container */
.answer-pattern-container {
    margin: 20px 0;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
}

/* Style for the heading */
.answer-pattern-container h4 {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

/* Style for the list */
.answer-pattern-container ul {
    list-style-type: none; /* Remove default list styling */
    padding: 0; /* Remove default padding */
}

/* Style for individual list items */
.answer-pattern-container ul li {
    padding: 8px;
    margin-bottom: 5px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
}

/* Add an icon or bullet before each item */
.answer-pattern-container ul li::before {
    content: '•'; /* Bullet point */
    color: #000000; /* Custom bullet color */
    font-size: 1.5rem;
    margin-right: 10px;
}

/* Hover effect for list items */
.answer-pattern-container ul li:hover {
    background-color: #f1f1f1;
}

/* Style for error message */
.error-message {
    color: #ff0000;
    font-weight: bold;
    margin-top: 10px;
}

/* Style for the download button */
.download-button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #114d8d;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.download-button:hover {
    background-color: #0056b3;
}



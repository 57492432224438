.logo-section {
    display: flex;
    align-items: center;
}

.logo {
    height: 70px;
    margin-right: 10px;
}

.logo-text {
    font-size: 24px;
    color:black;
}

.nav-links {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    margin: 0 20px;
}

.nav-links a {
    text-decoration: none;
    color: #333;
    padding: 15px 20px;
    font-size: 1.3em; /* Increased font size */
    position: relative;
    margin: 0 10px; /* Equal gap between links */
}

.nav-links a:hover,
.nav-links a.active {
    color: #d9534f; /* Change this color as per your theme */
}

.nav-links a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #d9534f; /* Change this color as per your theme */
    transition: width .3s;
}

.nav-links a:hover::after,
.nav-links a.active::after {
    width: 100%;
}


.register-button {
    display: flex;
    align-items: center;
}

.lds-header-menu-item-link a:hover::after,
.lds-header-menu-item-link a:active::after{
  color: #D52B1E;
}


/* Loading Page Styles */
.loading-body-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #e0e0e0, #cccccc); /* Warmer, muted gradient */
    overflow: hidden;
}

.loading-textContainer {
    text-align: center;
    background: rgba(250, 250, 250, 0.95); /* Slightly warm white */
    padding: 60px 120px; /* Adjusted padding to match the Coming Soon box */
    border-radius: 15px; /* Slightly rounded corners */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1); /* Gentle shadow */
    max-width: 80%; /* Adjusted max-width to match the Coming Soon box */
    animation: quickFadeIn 1s ease-in-out; /* Faster, smoother animation */
}

.loading-textContainer h1 {
    font-size: 2.5em; /* Slightly smaller font size */
    color: #555; /* Softer dark color */
    margin-bottom: 20px;
    text-transform: none; /* Removed uppercase */
    letter-spacing: 1px;
}

.loader {
    border: 8px solid #f3f3f3; /* Reduced border size */
    border-top: 8px solid #888; /* Softer color for loader */
    border-radius: 50%;
    width: 50px; /* Reduced size */
    height: 50px; /* Reduced size */
    animation: gentleSpin 1.5s linear infinite; /* Slightly slower spin */
    margin: 0 auto;
}

/* Animation for loader */
@keyframes gentleSpin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Fade-in animation */
@keyframes quickFadeIn {
    from {
        opacity: 0;
        transform: translateY(-10px); /* Less movement */
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.select-container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin-bottom: 20px;
}

.label {
    font-size: 1.2rem;
    margin-bottom: 8px;
    text-align: left;
}

.select-wrapper {
    position: relative;
    width: 100%;
}

.select-input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: #fff;
}

.clear-select, .clear-code, .clear-country {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 2rem;
    cursor: pointer;
    color: #dc3545;
}

.clear-select:hover, .clear-code:hover, .clear-country:hover {
    color: #c82333;
}

.code-select, .country-select {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content */
}

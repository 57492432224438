/* .table-container {
    padding: 20px;
    max-width: 95%;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
} */

.data-table {
    width: 100%; 
    border-collapse: collapse;
    margin-top: 20px;
    table-layout: fixed;
}

.data-table th, .data-table td {
    padding: 5px; /* Reduce padding for compactness */
    border: 2px solid #ddd;
    text-align: left; /* Align text to the left */
    vertical-align: middle; /* Center vertically */
    white-space: nowrap; /* Prevent text wrapping */
    --lds-typography-table-body-cell-align: left
}

.data-table th {
    background-color: #f1f1f1;
    text-align: left;
    vertical-align: middle;
    --lds-typography-table-head-cell-align: left
}



/* Basic Reset */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body, html {
    height: 100%;
    margin: 0;
    font-family: 'Arial', sans-serif;
    background: #ececec; /* Softer background */
  }
  
  /* Main Container */
  .coming-soon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(135deg, #e0e0e0, #cccccc); /* Warmer, muted gradient */
    overflow: hidden;
  }
  
  /* Content Box */
  .coming-soon-content {
    text-align: center;
    background: rgba(250, 250, 250, 0.95); /* Slightly warm white */
    padding: 60px 120px;
    border-radius: 15px; /* Slightly rounded corners */
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1); /* Gentle shadow */
    max-width: 80%;
    animation: quickFadeIn 1s ease-in-out; /* Faster, smoother animation */
  }
  
  /* Heading Styling */
  .coming-soon-content h1 {
    font-size: 2.5em;
    color: #555; /* Softer dark color */
    margin-bottom: 15px;
    letter-spacing: 1px;
  }
  
  /* Fade In Animation */
  @keyframes quickFadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
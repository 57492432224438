* {
    box-sizing: border-box; /* Ensures padding and border are included in the element's total size */
    margin: 0;
    padding: 0;
}

body, html {
    height: 100%;
    margin: 0;
}

.body-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    background: linear-gradient(135deg, #f0f0f0, #d9d9d9);
    font-family: 'Arial', sans-serif;
    overflow: hidden; /* Prevents overflow */
}

.textContainer {
    text-align: center;
    background: #fff;
    padding: 100px 200px; /* Increased padding */
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    max-width: 70%; /* Increased max width */
    animation: fadeIn 1s ease-in-out;
}

.textContainer h1 {
    font-size: 3em; /* Increased font size */
    color: #D52B1E;
    margin-bottom: 20px; /* Adjusted margin for spacing */
    text-transform: uppercase;
    letter-spacing: 2px;
}

.textContainer h2 {
    font-size: 1.5em; /* Increased font size */
    color: #000000;
    margin-top: 0;
    font-weight: 400;
    margin-bottom: 30px; /* Adjusted margin for spacing */
}

.contact-btn {
    display: inline-block;
    padding: 12px 30px; /* Increased padding */
    font-size: 1.2em; /* Increased font size */
    color: #fff;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 4px 15px rgba(255, 76, 76, 0);
}

.contact-btn:hover {
    background-color: #33323800;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

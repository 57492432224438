.dashboard-wrapper {
    padding: 20px;
    max-width: 98%; /* Increase the max-width for a wider container */
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dashboard-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: 100%;
}

.data-display {
    margin-top: 20px;
    width: 100%; /* Ensure the data display takes full width */
    align-items: center;
}

.table-wrapper{
    padding: 20px;
    max-width: 98%; /* Increase the max-width for a wider container */
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pagination-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.filters{
    width: 25%
}

.tabs-1, .tabs-2{
    --lds-color-tab-button-active-bg: rgb(10, 51, 153);
    --lds-color-tab-header-border: rgb(49, 139, 13);
    
}